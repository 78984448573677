// import './App.css';
import './brandy.css';
import { useEffect, useState, useRef } from 'react';
import { ReactComponent as Logo } from './brandy.svg';
import { ReactComponent as ExtLink } from './external-link.svg';

function EmojiComponent({ value }) {
  const renderEmoji = () => {
    if (value === null) {
      return "";
    } else if (value === true) {
      return "🟢";
    } else {
      return "🔴";
    }
  };

  return (<div className="emoji">{renderEmoji()}</div>);
}

function Domain({ domain }) {
  return (
    <tr>
      <td>{domain.name}</td>
      <td><a href={'//' + domain.name}
        target="_blank"
        rel="noopener noreferrer">
          <ExtLink className="external-link-icon" />
          </a></td>
      <td><EmojiComponent value={domain.available} /></td>
    </tr>
  );
}

function App() {
  const [inputValue, setInputValue] = useState('');
  const [prevInputValue, setPrevInputValue] = useState('');
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch('/api/brandy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ biz: inputValue }),
    }).then(res => res.json()).then(data => {
      setInputValue('');
      setLoading(false);
      setPrevInputValue(inputValue);
      setDomains(data.map((domain, index) => ({
        id: index,
        name: domain,
        available: null,
        loading: false,
      })));
    });
  };

  useEffect(() => {
    domains.forEach(domain => {
      if (domain.available === null && !domain.loading) {

        const updatedDomains = [...domains];
        updatedDomains[domain.id].loading = true;
        setDomains(updatedDomains);

        fetch('/api/available', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ domain: domain.name }),
        }).then(res => res.json()).then(data => {
          const updatedDomains = [...domains];
          updatedDomains[domain.id].available = data.available;
          updatedDomains[domain.id].loading = false;
          setDomains(updatedDomains);
        });
      }
    });

  }, [domains]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="container">
        <div className="content">
          <div className="title">
            <h1>Brandy</h1>
            <Logo className="logo" />
          </div>
          <div className="search-container">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                className="search-input"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="What are you building?"
                ref={inputRef}
              />
              <button type="submit" className="search-button">Submit</button>
            </form>
          </div>
        </div>
        <div className="results">
          {loading && <div className="loading-message">Loading...</div>}
          {domains.length !== 0 && <>
            <div className="results-table-title">
              Suggested names for <span style={{ color: '#ea600b' }}>{prevInputValue}</span>:
            </div>
            <table className="results-table">
              <thead>
                <th>Name</th>
                <th>Link</th>
                <th>Domain</th>
              </thead>
              <tbody>
                {domains.map(domain => (<Domain domain={domain} key={domain.id}/>))}
              </tbody>
            </table>
          </>}
        </div>
    </div>
  );
}

export default App;
